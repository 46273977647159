import * as React from 'react';
import { AccordionPanel } from './accordion-panel';
import './accordion.scss';

export type AccordionContent = {
  heading: string;
  content: JSX.Element;
}

export type AccordionType = {
  content: AccordionContent[]
}

export const Accordion: React.FC<AccordionType> = ({ content }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const setActive = (index: number) => () =>
    setActiveIndex(index);

  return (
    <div className="accordion">
      {content.map(({ heading, content: panel }, index) => (
        <AccordionPanel
          key={heading}
          heading={heading}
          content={panel}
          isActive={index === activeIndex}
          setActive={setActive(index)}
        />
      ))}
    </div>
  );
};
