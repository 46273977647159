import * as React from 'react';
import classNames from 'classnames';
import { AccordionContent } from './accordion';
import './accordion.scss';

export type AccordionPanel = AccordionContent & {
  isActive: boolean;
  setActive: () => void;
}

export const AccordionPanel: React.FC<AccordionPanel> = ({
  heading,
  content,
  isActive,
  setActive,
}) => (
  <div
    className={classNames('accordion__panel', { 'accordion__panel--active': isActive })}
    itemScope
    itemProp="mainEntity"
    itemType="https://schema.org/Question"
  >
    <div
      className="accordion__heading"
      onClick={setActive}
    >
      <h2 itemProp="name">{heading}</h2>
    </div>

    <div
      className="accordion__content"
      itemScope
      itemProp="acceptedAnswer"
      itemType="https://schema.org/Answer"
    >
      <div className="accordion__content-inner" itemProp="text">
        {content}
      </div>
    </div>
  </div>
);
