import * as React from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';
import Layout from '@components/layout/layout';
import { Accordion, AccordionContext } from '@components/accordion/accordion';

const Faq: React.FC = () => {
  const { t } = useTranslation();
  const translations: AccordionContext[] = t('pages.faq.accordion', { returnObjects: true });

  const accordionContent = translations.map(({ heading, content }) => ({
    heading,
    content: (
      <Trans
        i18nKey={content}
        components={{
          italic: <i />,
          bold: <strong />,
          a: <a target="_blank" />,
        }}
      />
    ),
  }));

  return (
    <Layout>
      <Helmet>
        <meta name="keywords" content={t('pages.faq.metaKeywords')} />
        <meta name="description" content={t('pages.faq.metaDescription')} />
        <title>{t('pages.faq.documentTitle')}</title>
      </Helmet>
      <div className="container">
        <h1>{t('pages.faq.title')}</h1>
        <Accordion content={accordionContent} />
      </div>
    </Layout>
  );
};

export default Faq;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
